import React from 'react';
import '../Common.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const Department = (props) => {
    const { name, id, setSelected } = props;

    return (
        <div className="flex-container">
        <Card className="card-custom">
            <Card.Body>
                <Card.Title>{name}</Card.Title>
                <Card.Link ></Card.Link>
                <Button variant="secondary" onClick={() => {setSelected(id)}}>Login</Button>
            </Card.Body>
        </Card>
        </div>
    );
};

export default Department;