import React from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import '../Common.css';

const Location = (props) => {
    const { name, id, setSelected } = props;

    return (
    // <div className="row">

        <div className="flex-container">
        <Card className="card-custom">
            <Card.Body>
                <Card.Title>{name}</Card.Title>
                <br></br>
                <Card.Text >ID: {id}</Card.Text>                
                <Button variant="secondary" onClick={() => {setSelected(id)}}
                style={{ marginLeft: '180px'}}
                >Login</Button>
            </Card.Body>
        </Card>

        </div>


    // </div>
    );
};

export default Location;