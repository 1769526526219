import React, { useState } from 'react';

import FormControl from 'react-bootstrap/FormControl';


import { BookingTable } from './BookingTable';
import './BookingTable.css';

const Booking = (props) => {
    const { reloadBookings, status, updateArrived, updateRejected, updateArrivedAndPaid, updateNoShow, updateRemoveStatus, updateNotes,
        arrivedLoading, rejectedLoading, arrivedAndPaidLoading, noShowLoading, removeLoading, locationId, getBookings, markAsNoShow,
        markNoShowLoading } = props;
    const [q, setQ] = useState("");

    const colNames = ["Due", "Booking Ref", "Name", "Postcode", "Vehicle Reg"];

    function arrived(obj) {
        updateArrived(obj,setQ)
    }

    function rejected(obj) {
        updateRejected(obj,setQ)
    }

    function arrivedAndPaid(obj) {
        updateArrivedAndPaid(obj,setQ)
    }

    function noShow(obj) {
        updateNoShow(obj,setQ)
    }

    function removeStatus(obj) {
        updateRemoveStatus(obj,setQ);
    }

    function bookingNotes(obj, notes) {
        updateNotes(obj, notes);
    }

    function filter(value) {
        return value.filter(
            (x) => {
                console.log(x);
                if (!x.time) {
                    return x.client_name?.toLowerCase().indexOf(q.toLowerCase()) > -1 ||
                        x.answers_summary[0]?.answer?.toLowerCase().replace(/\s/g, "").indexOf(q.toLowerCase()) > -1 ||
                        x["_embedded"].client?.postcode?.toLowerCase().replace(/\s/g, "").indexOf(q.toLowerCase()) > -1 ||
                        x.id?.toString().indexOf(q) > -1 &&
                        x.status != 3
                } else if (x.time) {
                    console.log(q);
                    if (q != "") {
                        if (x.vehicle_reg) {
                            return !x.vehicle_reg?.toLowerCase().indexOf(q.toLowerCase());
                        }
                        else {
                            let found = false;
                            for (const question of x.nested_questions) {
                                if (question.answer.toLowerCase().indexOf(q.toLowerCase()) !== -1) {
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                if (x.id.toString().indexOf(q.toLowerCase()) !== -1) {
                                    found = true;
                                }
                            }
                            if (!found) {
                                if (x.name.toLowerCase().indexOf(q.toLowerCase()) !== -1) {
                                    found = true;
                                }
                            }
                            // do one more check of the client postcode
                            if (!found) {
                                if (x["_embedded"].client?.postcode?.toLowerCase().replace(/\s/g, "").indexOf(q.toLowerCase()) > -1) {
                                    found = true;
                                }
                            }
                            return found;
                        }
                    }
                    return true;
                } else {
                    return true;
                }
            }
        );
    }

    return (
        <div className="container" style={{ alignItems: 'start', marginBottom: '10px' }}>
            <FormControl
                value={q} type="text" onChange={(e) => setQ(e.target.value)}
                style={{ marginBottom: '10px', width: '200px' }}
                placeholder="Search..."
                aria-label="Search"
            />
            <BookingTable bookings={filter(reloadBookings)} colNames={colNames}
                setArrived={arrived}
                setRejected={rejected}
                setArrivedAndPaid={arrivedAndPaid}
                setNoShow={noShow}
                setRemoveStatus={removeStatus}
                setSaveNotes={bookingNotes}
                status={status}
                arrivedLoading={arrivedLoading}
                rejectedLoading={rejectedLoading}
                arrivedAndPaidLoading={arrivedAndPaidLoading}
                noShowLoading={noShowLoading}
                removeLoading={removeLoading}
                locationId={locationId}
                getBookings={() => getBookings()}
                markAsNoShow={markAsNoShow}
                markNoShowLoading={markNoShowLoading}></BookingTable>
        </div>
    );
};

export default Booking;