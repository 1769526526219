import React, { useMemo, useContext, useState, useEffect, useRef } from "react";
import moment from 'moment';
import { Container, Stack, Spinner, Button } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import { PersonCheck, PersonDash, PersonX, Camera, CardText, Save, Save2 } from 'react-bootstrap-icons';
import { SettingsContext } from "../../context/SettingsContext";
import axios from 'axios';
import MakeNotes from "../features/MakeNotes";
import TakePicture from "../features/TakePicture";

import { COLUMNS } from "./booking-columns";
import Service from "../../api/ApiService";

export const BookingTable = (props) => {

    const { bookings, status, setArrived, setRejected, setArrivedAndPaid, setNoShow, setRemoveStatus, setSaveNotes, setQ, arrivedLoading, rejectedLoading, arrivedAndPaidLoading, noShowLoading, removeLoading, locationId, getBookings, markAsNoShow, markNoShowLoading } = props;

    const [selected, setSelected] = useState(0);

    // column which will display the action buttons
    let actionsColumn = 0;

    let filteredBookings = [];
    let hiddenColumns = [];

    // bespoke for reading rc START
    const [showDetails, setShowDetails] = useState(false);
    const [detailsValue1, setDetailsValue1] = useState("");
    const [detailsValue2, setDetailsValue2] = useState("");
    const [detailsValue3, setDetailsValue3] = useState("");
    const [detailsValue4, setDetailsValue4] = useState("");
    const [detailsValue5, setDetailsValue5] = useState("");
    const [detailsValue6, setDetailsValue6] = useState("");
    const [detailsValue7, setDetailsValue7] = useState("");
    const [detailsValue8, setDetailsValue8] = useState("");
    const [detailsValue9, setDetailsValue9] = useState("");
    const [detailsValue10, setDetailsValue10] = useState("");
    const [detailsValue11, setDetailsValue11] = useState("");
    const [detailsValue12, setDetailsValue12] = useState("");
    const [detailsValue13, setDetailsValue13] = useState("");
    const [detailsValue14, setDetailsValue14] = useState("");
    const [address, setAddress] = useState("");
    const [chargeableWaste, setChargeableWaste] = useState([]);
    // bespoke for reading rc END

    const { settings } = useContext(SettingsContext);

    let title;

    let tableCols = [];

    let enabledFeatures = { "notes": false, "camera": false, "markAllNoShow": false, "details": false, "rejected": false, "arrivedAndPaid": false };


    if (settings) {
        let k = -1;
        switch (status) {
            case "DUE":
                k = findSettings(settings, "due")
                enableFeatures(settings[k].due.features);
                tableCols = settings[k].due.columns;
                filterColumns(settings[k].due.name, tableCols);
                break;
            case "ARRIVED":
                k = findSettings(settings, "arrived")
                tableCols = settings[k].arrived.columns;
                filterColumns(settings[k].arrived.name, tableCols);
                break;
            case "REJECTED":
                k = findSettings(settings, "rejected")
                tableCols = settings[k].rejected.columns;
                filterColumns(settings[k].rejected.name, tableCols);
                break;
            case "ARRIVEDANDPAID":
                k = findSettings(settings, "arrived_and_paid")
                tableCols = settings[k].arrived_and_paid.columns;
                filterColumns(settings[k].arrived_and_paid.name, tableCols);
                break;
            case "NOSHOW":
                k = findSettings(settings, "noshow")
                tableCols = settings[k].noshow.columns;
                filterColumns(settings[k].noshow.name, tableCols);
                break;
            case "NOBOOKING":
                k = findSettings(settings, "noxbookings")
                tableCols = settings[k].noxbookings.columns;
                filterColumns(settings[k].noxbookings.name, tableCols);
                break;
            default:
                break;
        }
    }

    function findSettings(settings, field) {
        for (let i in settings) {
            if (settings[i][field] !== undefined) {
                return i
            }
        }
        return -1;
    }

    function enableFeatures(features) {
        for (const feature of features) {
            enabledFeatures[feature] = true;
        }
    }

    function handleNotesChange() {
        getBookings();
    }

    function filterColumns(tab, tabColumns) {
        actionsColumn = tabColumns.length;
        title = tab;
        bookings.forEach((item, i) => {
            switch (true) {
                case tab === "Due":
                    if (item.multi_status.checked_in === undefined && item.multi_status.no_show === undefined && item.multi_status.rejected === undefined && item.multi_status.arrived_and_paid === undefined) {
                        break;
                    } else {
                        return;
                    }
                case tab === "Arrived":
                    if (item.multi_status.checked_in != undefined && item.multi_status.no_show === undefined) {
                        break;
                    } else {
                        return;
                    }
                case tab === "Rejected":
                    if (item.multi_status.rejected != undefined && item.multi_status.no_show === undefined) {
                        break;
                    } else {
                        return;
                    }
                case tab === "Arrived and paid":
                    if (item.multi_status.arrived_and_paid != undefined && item.multi_status.no_show === undefined) {
                        break;
                    } else {
                        return;
                    }
                case tab === "No Show":
                    if (item.multi_status.checked_in === undefined && item.multi_status.no_show != undefined) {
                        break;
                    } else {
                        return;
                    }
                case tab === "No Bookings":
                    // if (item.multi_status.checked_in === undefined && item.multi_status.no_show === undefined) {
                    break;
            }

            let columns = {};
            tabColumns.forEach(column => { // columns defined in checkin_app_settings on amazon web services
                // columns['sortBy'] = item.datetime;
                if (column.value) {
                    if (column.value.type == "item") {
                        columns[column.value.field] = item[column.value.name]
                        if (column.value.answer_conversion_type == "boolean") {
                            columns[column.value.field] = item[column.value.name]?.answer === "true" ? "Yes" : "No";
                        }
                        if (column.value.name == "name") {
                            if (typeof item[column.value.name] == "undefined") {
                                columns[column.value.name] = item.client_name;
                            }
                        }
                        if (tab === "No Bookings") {
                            if (columns[column.value.field] && columns[column.value.field].toString() === 'true' || columns[column.value.field] === 'false') {
                                if (columns[column.value.field].toString() === 'true') {
                                    columns[column.value.field] = 'Yes';
                                }
                                if (columns[column.value.field].toString() === 'false') {
                                    columns[column.value.field] = '';
                                }
                            }
                        }
                    }
                    if (column.value.type == "time") {
                        if (tab === "Arrived") {
                            columns[column.value.field] = moment(item.multi_status.checked_in).format('HH:mm');
                        }
                        else {
                            if (item.datetime) {
                                columns[column.value.field] = moment(item.datetime).format('HH:mm')
                            } else {
                                columns[column.value.field] = item.time;
                            }
                        }
                    }
                    if (column.value.type == "date") {
                        columns[column.value.field] = moment(item.date).format('YYYY-MM-DD')
                    }

                    if (column.value.type == "answers_summary") {
                        if (item.nested_questions) { // new data return format
                            columns[column.value.field] = item.nested_questions[column.value.name]?.answer // answers_summary[column.value.name]?.answer
                        }
                        if (item.answers_summary) {
                            columns[column.value.field] = item.answers_summary[column.value.name]?.answer // answers_summary[column.value.name]?.answer
                        }
                    }
                    if (column.value.type == "question") {
                        columns[column.value.field] = column.value.case_conversion == 'uppercase' ?
                            item.questions[column.value.name]?.answer.toUpperCase()
                            :
                            item.questions[column.value.name]?.answer

                        if (column.value.answer_conversion_type == "boolean") {
                            if (column.title !== "Assistance") { // for https://bookinglab.atlassian.net/browse/KCC-115
                                columns[column.value.field] = item.questions[column.value.name]?.answer === "true" ? "Yes" : "No";
                            } else{
                                const answerValue = item.questions[column.value.name]?.answer;
                                if (answerValue === "true" || answerValue === true) {
                                    columns[column.value.field] = "Yes";
                                }
                                else {
                                    columns[column.value.field] = "";
                                }
                            }
                        }
                        if (column.value.answer_conversion_type == "string") {

                            columns[column.value.field] = item.questions[column.value.name]?.answer ? "Yes" : "No"

                            if (column.value?.alt_text_required == 'yes') {
                                if (!item.questions[column.value.name]?.answer) {
                                    columns[column.value.field] = "No"
                                }
                            } else {
                                if (!item.questions[column.value.name]?.answer || item.questions[column.value.name]?.answer == undefined) {
                                    columns[column.value.field] = ""
                                }
                            }
                        }
                        if (column.value.condition) {
                            if (column.value.condition.type == 'question' && !item.questions[column.value.condition.name]?.answer) {
                                columns[column.value.field] = "";
                            }
                        }
                    }
                    if (column.value.type == "embedded_client") {
                        if (item._embedded.hasOwnProperty('client')) {
                            columns[column.value.field] =
                                column.value.case_conversion == 'uppercase' ?
                                    item['_embedded'].client[column.value.name] ? item['_embedded'].client[column.value.name].toUpperCase() : ""
                                    :
                                    item['_embedded'].client[column.value.name]
                        }
                        else {
                            columns[column.value.field] = "";
                        }
                    }
                    if (column.value.type == "multi_stats_time") { // arrived's check in time
                        columns[column.value.field] = moment(item?.multi_status?.checked_in).format('HH:mm');
                    }
                    if (column.value.type == "multi_stats_time_paid") {// arrived and paid's check in time
                        columns[column.value.field] = moment(item?.multi_status?.arrived_and_paid).format('HH:mm');
                    }
                    if (column.value.type == "question_flag") {
                        if (item.questions[column.value.name]?.answer) {
                            columns[column.value.field] = "<b style='font-size:30px'>🚩</b>";
                        } else {
                            columns[column.value.field] = "";
                        }
                    }
                    if (column.value.type == "question_multi_answer_flag") {
                        columns[column.value.field] = "";
                        for (const question in item.questions) {
                            const q = item.questions[question];
                            if (column.value.names.indexOf(question) != -1) {
                                if (q.answer === 'true') {
                                    columns[column.value.field] = "<b style='font-size:30px'>🚩</b>";
                                    break;
                                }
                            }
                        }
                    }
                    if (column.value.type == "questions_multi") {

                        let items = "";

                        column.value.names.forEach(name => {
                            if (item.questions[name]) {

                                if (items != "") {
                                    items += " <br> " + item.questions[name].answer;
                                } else {
                                    items = item.questions[name].answer;
                                }
                            }
                        });

                        columns[column.value.field] = items;
                    }

                    // global config for adding flag to value. If config has flag and flagFields fields, use them to check value.
                    if (column.value.hasOwnProperty('flag')) {
                        if (column.value.flag) {
                            if (column.value.flagFields) {
                                if (item.questions[column.value.name] && item.questions[column.value.name].hasOwnProperty('answer_id')) {
                                    if (column.value.flagFields.indexOf(item.questions[column.value.name].answer_id) !== -1) {
                                        columns[column.value.field] += " <b style='font-size:30px'>🚩</b>";
                                    }
                                }
                            }
                        }
                    }
                }
                if (column.hidden) {
                    hiddenColumns.push(column.value.field);
                }
            })
            filteredBookings.push(columns);
        });

        const k = findSettings(settings, "due");

        if (tab === "Due") {
            if (settings && settings[k].due.hasOwnProperty('fieldSort')) {
                // sort by filter in settings
                filteredBookings.sort(function (x, y) {
                    const field = settings[k].due.fieldSort;
                    if (x[field] && y[field]) {
                        let nameA = x[field].toLowerCase(), nameB = y[field].toLowerCase();

                        if (nameA < nameB) //sort string ascending
                            return -1
                        if (nameA > nameB)
                            return 1
                        return 0 //default return value (no sorting)
                    }
                });
            }
            else {
                // sort by vehicle reg
                filteredBookings.sort(function (x, y) {
                    if (x.vehicle_registration && y.vehicle_registration) {
                        let nameA = x.vehicle_registration.toLowerCase(), nameB = y.vehicle_registration.toLowerCase();

                        if (nameA < nameB) //sort string ascending
                            return -1
                        if (nameA > nameB)
                            return 1
                        return 0 //default return value (no sorting)
                    }
                });
            }
        }

        // sort by due
        filteredBookings.sort(function (x, y) {

            let a = moment(x.due, 'HH:mm'),
                b = moment(y.due, 'HH:mm');

            return a - b;
        })
        if (tab === 'Due') {
            filteredBookings.sort(function (x, y) {

                let a = moment(x.due, 'HH:mm'),
                    b = moment(y.due, 'HH:mm');

                return a - b;
            })
        }
        if (tab === 'Arrived' || tab === 'Arrived and paid') {
            filteredBookings.sort(function (x, y) {

                let a = moment(x.arrived, 'HH:mm'),
                    b = moment(y.arrived, 'HH:mm');

                if (a.isAfter(b)) {
                    return -1;
                }
                if (a.isBefore(b)) {
                    return 1;
                }
                return 0;
            })
        }
        console.log(tab);
        if (tab === 'No Bookings') {
            filteredBookings.sort(function (x, y) {
                
                const xDateTime = `${x.Date} ${x.Time}`;
                const yDateTime = `${y.Date} ${y.Time}`;
                let a = moment(xDateTime),
                    b = moment(yDateTime);
    
                if (a.isAfter(b)) {
                    return -1;
                }
                if (a.isBefore(b)) {
                    return 1;
                }
                return 0;
            })
        }
    }

    function details(obj) {

        if (obj) {
            bookings.forEach(async booking => {


                if (booking.id == obj.booking_ref) {

                    // rest all values
                    setDetailsValue3("");
                    setDetailsValue4("");
                    setDetailsValue5("");
                    setDetailsValue6("");
                    setDetailsValue7("");
                    setDetailsValue8("");
                    setDetailsValue9("");
                    setDetailsValue10("");
                    setDetailsValue11("");
                    setDetailsValue12("");
                    setDetailsValue13("");
                    setDetailsValue14("");

                    if (!booking._embedded.client) {
                        const service = new Service();
                        const client = await service.getClient(booking.company_id, booking.member_id);
                        booking["_embedded"].client = client;
                    }
                    let address = "Address: ";
                    if (booking["_embedded"].client.address1) {
                        address += booking["_embedded"].client.address1;
                    } else {
                        address += "n/a";
                    }
                    setAddress(address);
                    let hireVehicle = "Hire vehicle: ";
                    if (booking.questions[34]?.answer) {
                        hireVehicle += booking.questions[34]?.answer;
                    } else {
                        hireVehicle += "n/a";
                    }
                    setDetailsValue1(hireVehicle);
                    let vehicleReg = "Vehicle reg: ";
                    if (booking.questions[4]?.answer) {
                        vehicleReg += booking.questions[4]?.answer;
                    } else {
                        vehicleReg += "n/a";
                    }
                    setDetailsValue2(vehicleReg);

                    if (booking.questions[13]?.answer === 'true' || booking.questions[13]?.answer === '1') {
                        const furniture = "Furniture: Yes";
                        setDetailsValue3(furniture);
                    }
                    if (booking.questions[14]?.answer === 'true' || booking.questions[14]?.answer === '1') {
                        const diyWaste = "DIY Waste: Yes";
                        setDetailsValue4(diyWaste);
                    }

                    if (booking.questions[15]?.answer === 'true' || booking.questions[15]?.answer === '1') {
                        const blackBagReWaste = "Black bag/residual waste: Yes";
                        setDetailsValue5(blackBagReWaste);
                    }

                    if (booking.questions[16]?.answer === 'true' || booking.questions[16]?.answer === '1') {
                        const greenGardenWaste = "Green garden waste: Yes";
                        setDetailsValue6(greenGardenWaste);
                    }
                    if (booking.questions[17]?.answer === 'true' || booking.questions[17]?.answer === '1') {
                        const electricalWaste = "Electrical waste: Yes";
                        setDetailsValue7(electricalWaste);
                    }
                    if (booking.questions[18]?.answer === 'true' || booking.questions[18]?.answer === '1') {
                        const smallRecyclables = "Small recyclables: Yes";
                        setDetailsValue8(smallRecyclables);
                    }
                    if (booking.questions[19]?.answer === 'true' || booking.questions[19]?.answer === '1') {
                        const textilesAndShoes = "Textiles and shoes: Yes";
                        setDetailsValue9(textilesAndShoes);
                    }
                    if (booking.questions[20]?.answer === 'true' || booking.questions[20]?.answer === '1') {
                        const paintOilOrChemicals = "Paint, oil or chemicals: Yes";
                        setDetailsValue10(paintOilOrChemicals);
                    }
                    if (booking.questions[21]?.answer === 'true' || booking.questions[21]?.answer === '1') {
                        const booksAndMedia = "Books and media: Yes";
                        setDetailsValue11(booksAndMedia);
                    }
                    if (booking.questions[22]?.answer === 'true' || booking.questions[22]?.answer === '1') {
                        const cardboard = "Cardboard: Yes";
                        setDetailsValue12(cardboard);
                    }
                    if (booking.questions[23]?.answer === 'true' || booking.questions[23]?.answer === '1') {
                        const scrapMetal = "Scrap metal: Yes";
                        setDetailsValue13(scrapMetal);
                    }
                    if (booking.questions[24]?.answer === 'true' || booking.questions[24]?.answer === '1') {
                        const otherWaste = "Other Waste: Yes";
                        setDetailsValue14(otherWaste);
                    }

                    let chargeablewaste = [];

                    if (booking.questions?.[26]?.answer === 'true' || booking.questions?.[26]?.answer === '1') {
                        chargeablewaste.push({ "name": "Rubble: Yes" });
                    } else {
                        chargeablewaste.push({ "name": "Rubble: No" });
                    }

                    if (booking.questions?.[27]?.answer === 'true' || booking.questions?.[27]?.answer === '1') {
                        chargeablewaste.push({ "name": "Soil: Yes" });
                    } else {
                        chargeablewaste.push({ "name": "Soil: No" });
                    }

                    if (booking.questions?.[28]?.answer === 'true' || booking.questions?.[28]?.answer === '1') {
                        chargeablewaste.push({ "name": "Plasterboard: Yes" });
                    } else {
                        chargeablewaste.push({ "name": "Plasterboard: No" });
                    }

                    if (booking.questions?.[29]?.answer === 'true' || booking.questions?.[29]?.answer === '1') {
                        chargeablewaste.push({ "name": "Gas canisters: Yes" });
                    } else {
                        chargeablewaste.push({ "name": "Gas canisters: No" });
                    }

                    setChargeableWaste(chargeablewaste);
                }
            })
        }

        setShowDetails(!showDetails);
    }

    function selectedRow(obj) {
        setSelected(obj.booking_ref);
    }

    function getValue(value) {
        let label = "";

        filteredBookings.forEach(element => {
            const found = Object.keys(element).filter(function (key) { return element[key] === value })[0];

            if (found != undefined) {
                label = translateToLabel(found);
            }
        })

        return label;
    }

    function getWidth(value) {
        if (value == 'permit_type') {
            return '20%';
        }
        return 'auto';
    }

    function translateToLabel(label) {
        switch (true) {
            case label === "vehicle_colour":
                return "Vehicle Colour";

            case label === "vehicle_registration":
            case label === "vehicle_reg":
                return "Vehicle Reg";

            case label === "booking_ref":
                return "Booking Ref";

            case label === "due":
                return "Due";
            case label === "postcode":
                return "Postcode";
            case label === "collection_contact_telephone":
                return "Contact number";
            case label === "location":
                return "Location";

            default:
                return label;
        }

    }

    function getHiddenColumn(obj, index2) {
        const hidden = Object.keys(obj)[index2];
        if (hiddenColumns.length > 0) {
            for (const column of hiddenColumns) {
                if (column == hidden) {
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }
    }

    return (
        <>
            <div style={{ width: '100%', marginBottom: '10px' }}>
                <b>
                    <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>{title} ({filteredBookings.length})</h4>
                </b>
            </div>

            <Table bordered variant="light" className="table">
                <thead className="thead-dark">
                    <tr>{tableCols.map((d, index) => {
                        if (!d.hidden) {
                            return <th key={d.index}>{d.title} {d.hidden}</th>
                        }
                    })}

                        {/* This is for the actions column */}
                        {status != "NOBOOKING" && filteredBookings.length > 0 &&
                            <th></th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {Object.values(filteredBookings).map((obj, index) => (
                        <tr key={index} >
                            <>
                                {Object.values(obj).map((value, index2) => {
                                    if (index2 != actionsColumn && getHiddenColumn(obj, index2)) return (
                                        <td key={index2} data-label={getValue(value)} dangerouslySetInnerHTML={{ __html: value }} width={getWidth(getValue(value))} ></td>
                                    )
                                })}
                            </>
                            {status === "NOBOOKING" ? null :
                                <td style={{ width: 'auto', tableLayout: '' }} key={index}>
                                    {status === "DUE" ?
                                        <Stack className="table-button-container" direction="horizontal" gap={2}>
                                            <>
                                                <Button className="table-button" variant="success" onClick={() => { setArrived(obj); selectedRow(obj) }}>
                                                    <label className="btn-text">Arrived</label>
                                                    {arrivedLoading && selected === obj.booking_ref ?
                                                        <Spinner className="ms-1" variant="light" as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                        :
                                                        <PersonCheck size={25} className="ms-1" />
                                                    }
                                                </Button>
                                                {(enabledFeatures.rejected) ?
                                                    <Button className="table-button" variant="danger" onClick={() => { setRejected(obj); selectedRow(obj) }}>
                                                        <label className="btn-text">Rejected</label>
                                                        {rejectedLoading && selected === obj.booking_ref ?
                                                            <Spinner className="ms-1" variant="light" as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                            :
                                                            <PersonCheck size={25} className="ms-1" />
                                                        }
                                                    </Button>
                                                    : ''}
                                                {(enabledFeatures.arrivedAndPaid) ?
                                                    <Button className="table-button" variant="danger" onClick={() => { setArrivedAndPaid(obj); selectedRow(obj) }}>
                                                        <label className="btn-text">Arrived and paid</label>
                                                        {arrivedAndPaidLoading && selected === obj.booking_ref ?
                                                            <Spinner className="ms-1" variant="light" as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                            :
                                                            <PersonCheck size={25} className="ms-1" />
                                                        }
                                                    </Button>
                                                    : ''}
                                                <Button className="table-button" variant="warning" onClick={() => { setNoShow(obj); selectedRow(obj) }}>
                                                    <label className="btn-text">No show</label>
                                                    {noShowLoading && selected === obj.booking_ref ?
                                                        <Spinner className="ms-1" variant="light" as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                        :
                                                        <PersonX size={25} className="ms-1" />
                                                    }
                                                </Button>

                                                {enabledFeatures.notes &&
                                                    <MakeNotes bookings={bookings} obj={obj} locationId={locationId} handleNotesChange={() => handleNotesChange()} />
                                                }
                                                {enabledFeatures.camera &&
                                                    <TakePicture obj={obj} />
                                                }
                                                {enabledFeatures.details &&
                                                    ((obj.please_confirm_the_type_of_vehicle_you_will_be_to && obj.please_confirm_the_type_of_vehicle_you_will_be_to.indexOf("Car with trailer (6ft-10ft)") !== -1) ||
                                                        (obj.please_confirm_the_type_of_vehicle_you_will_be_to && obj.please_confirm_the_type_of_vehicle_you_will_be_to.indexOf("Van/Pick up") !== -1) ||
                                                        (obj.please_confirm_the_type_of_vehicle_you_will_be_to && obj.please_confirm_the_type_of_vehicle_you_will_be_to.indexOf("Sign written vehicle") !== -1)) &&
                                                    <Button className="table-button" onClick={() => { selectedRow(obj); details(obj) }}><label className="btn-text">Details</label></Button>
                                                }
                                            </>

                                        </Stack>
                                        :
                                        <Stack className="table-button-container" direction="horizontal" gap={2}>
                                            <Button className="table-button-remove" variant="danger" onClick={() => { setRemoveStatus(obj); selectedRow(obj) }} >
                                                <label className="btn-text">Remove</label>
                                                {removeLoading && selected === obj.booking_ref ?
                                                    <Spinner variant="light" as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                    :
                                                    <PersonDash size={25} className="ms-1" />
                                                }
                                            </Button>

                                        </Stack>
                                    }

                                    {showDetails && selected === obj.booking_ref &&

                                        <>
                                            <Container style={{ alignItems: 'flex-start' }}>
                                                <span>{address}</span>
                                                <span>{detailsValue1}</span>
                                                <span>{detailsValue2}</span>
                                                <br />
                                                <span><b>Non-Chargeable Waste:</b></span>
                                                <span>{detailsValue3}</span>
                                                <span>{detailsValue4}</span>
                                                <span>{detailsValue5}</span>
                                                <span>{detailsValue6}</span>
                                                <span>{detailsValue7}</span>
                                                <span>{detailsValue8}</span>
                                                <span>{detailsValue9}</span>
                                                <span>{detailsValue10}</span>
                                                <span>{detailsValue11}</span>
                                                <span>{detailsValue12}</span>
                                                <span>{detailsValue13}</span>
                                                <span>{detailsValue14}</span>

                                                <br />
                                                {chargeableWaste.length > 0 && <span><b>Chargeable Waste:</b></span>}
                                                {Object.values(chargeableWaste).map((value, index) => {
                                                    return <span>{value.name}</span>
                                                })}
                                            </Container>
                                        </>

                                    }

                                </td>
                            }
                        </tr>
                    ))}
                    {filteredBookings.length === 0 &&
                        <tr>
                            <td colSpan={actionsColumn} className="text-center">No data available</td>
                        </tr>
                    }
                </tbody>
            </Table>

            {title === "Due" && filteredBookings.length != 0 && enabledFeatures.markAllNoShow &&

                <Container>
                    <Button size="md" variant="warning" onClick={() => { markAsNoShow(setQ); }} style={{ height: '60px' }} >
                        Mark All As No Show
                        {markNoShowLoading ?
                            <Spinner className="ms-1" variant="light" as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                            :
                            ""
                        }
                    </Button>
                </Container>
            }
        </>
    )

}
