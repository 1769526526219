import React, { useContext, useState, useEffect } from "react";
import { FloatingLabel } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from 'axios';
import { useHistory, useParams } from "react-router-dom";

import './Login.css';
import { setUserLogin, setCurrentPage as setDefaultPage, getCurrentPage } from "../Utils/Common";
import { UserContext } from "../context/UserContext";
import { UserInfoContext } from "../context/UserInfoContext";
import { SiteContext } from "../context/SiteContext";
import { CurrentPageContext } from "../context/CurrentPageContext";
import { SettingsContext } from "../context/SettingsContext";
import { AdminUserContext } from "../context/AdminUserContext";
import { LocationContext } from "../context/LocationContext";

export default function Login(props) {

    const [apiUrl, setApiUrl] = useState('');
    // const [companyName, setCompanyName] = useState('');

    let companyName = "";

    const { customer } = useParams();

    useEffect(() => {
        getCustomer(customer);
    }, []);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const { setAdminUser } = useContext(AdminUserContext);
    const { setLoggedIn } = useContext(UserContext);
    const { setUrl } = useContext(SiteContext);
    const { setSelectedLocation } = useContext(LocationContext);
    const { setUserInfo } = useContext(UserInfoContext);

    const { setSettings } = useContext(SettingsContext);

    const history = useHistory();

    const routeChange = (page) => {
        let path = "/" + sessionStorage.customer + "/" + page;
        history.push(path);
    }

    // let authKey = localStorage.getItem('authKey');
    // if (authKey) {
    //     let currentPage = getCurrentPage();
    //     setLoggedIn(authKey);
    //     routeChange(currentPage);
    // }

    function validateForm() {
        return email.length > 0 && email.length > 0 && email.includes("@") && email.includes(".") && password.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        login("user");
    }

    const handleLogin = () => {
        this.props.history.push('./home');
    }

    async function getCompanyId() {

        const data = {
            company: companyName
        };

        sessionStorage.setItem('companyName', companyName.charAt(0).toUpperCase() + companyName.slice(1));

        //header

        const headers = {
            "Content-Type": "application/json",
            "x-api-key": "r1OK0DiEqb8CrrGI6Mjm56qXe9oblUx27sA5olIY",
        };

        // const result = await axios.post('https://ffs4ovo9dg.execute-api.eu-west-1.amazonaws.com/prod/settingsById',
        await axios.get('https://ffs4ovo9dg.execute-api.eu-west-1.amazonaws.com/prod/companyId', { params: data }, {
            headers: headers
        }).then((response) => {
            sessionStorage.setItem('companyid', response.data.item[0].companyId);

            const host = window.location.host.split('.', 1)[0];
            const staging = window.location.host.split('.')[1];
            let api_url = "";
            if (staging === "staging" || host.indexOf('localhost') !== -1 ) {
                api_url = response.data.api.apiUrl.staging;
                console.debug(`App in staging mode: ${api_url}`);
            } else {
                api_url = response.data.api.apiUrl.prod;
                console.debug(`App in production mode: ${api_url}`);
            }
            sessionStorage.setItem('apiUrl', api_url);
            setApiUrl(api_url);
        }).catch((requestError) => {
            console.log("error", requestError);
        })
    }

    async function getCustomer(customer) {

        const data = {
            customer: customer
        };

        sessionStorage.setItem('customer', customer);

        //header

        const headers = {
            "Content-Type": "application/json",
            "x-api-key": "r1OK0DiEqb8CrrGI6Mjm56qXe9oblUx27sA5olIY",
        };

        await axios.get('https://ffs4ovo9dg.execute-api.eu-west-1.amazonaws.com/prod/customer', { params: data }, {
            headers: headers
        }).then((response) => {

            if (response.data.length == 0) {
                history.push('/' + sessionStorage.customer + '/404');
            };

            companyName = response.data[0].company;

            sessionStorage.setItem("companyName", companyName);

            getCompanyId();

        }).catch((requestError) => {
            console.log("error", requestError);
        })
    }

    async function getSettings() {

        const company = sessionStorage.companyName;

        const requestBody = {
            company: company,
            type: "tab",
            setting: "due"
        };

        //header
        let requestConfig = {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": "r1OK0DiEqb8CrrGI6Mjm56qXe9oblUx27sA5olIY",
            }
        };

        // const result = await axios.post('https://ffs4ovo9dg.execute-api.eu-west-1.amazonaws.com/prod/settingsById',
        await axios.post('https://ffs4ovo9dg.execute-api.eu-west-1.amazonaws.com/prod/settingsByType',
            requestBody, requestConfig).then((response) => {
                setSettings(response.data);
            }).catch((requestError) => {
                console.log("error", requestError);
            })
    }

    async function login(user) {

        setLoading(true);
        setError(null);

        //body
        let params = { email, password };
        //header
        let headers = {
            "Content-Type": "application/json",
            "App-Id": "f6b16c23",
            "App-Key": "f0bc4f65f4fbfe7b4b3b7264b655f5eb"
        };

        let url = "";

        if (user === "admin") {
            url = apiUrl + "/login/admin/" + sessionStorage.getItem('companyid')
        } else {
            url = apiUrl + "/login/admin";
        }

        await axios.post(url, params,
            {
                headers: headers
            }).then(async function (response) {
                await getSettings();
                setLoading(false);
                setLoggedIn(response.data.auth_token);
                setDefaultPage('departments');
                setUserLogin(response.data.auth_token, response.data._embedded, apiUrl);
                if (response.data._embedded.administrators[0]) {
                    const userInfo = response.data._embedded.administrators[0]
                    const role = userInfo.role;
                    setUserInfo(userInfo);
                    if (role === "owner") {

                        const admin = {
                            role: role,
                            info: userInfo
                        }

                        setAdminUser(admin);
                        routeChange('departments');
                    } else {
                        setAdminUser({ info: userInfo });
                        setSelectedLocation(userInfo.company_id);
                        routeChange('bookings');
                    }
                }
            }).catch(error => {
                setLoading(false);
                if (error.response && error.response.status === 401) {
                    setError("Invalid email or password");
                } else if (error.response && error.response.status === 400) {
                    login("admin");
                } else {
                    console.log('error', error);
                    setError("Something went wrong. Please try again later.");
                }
            })
    };


    return (
        <div className="loginBoxContainer">
            <div className="boxContainer">
                <div className="topContainer">
                    <div className="backDrop">
                    </div>
                    <div className="headerContainer">
                        <div className="headerText">
                            Welcome
                        </div>
                        <div className="smallText">
                            Please sign-in to continue
                        </div>
                    </div>
                </div>
                <div className="innerContainer">
                    <div className="formBoxContainer">
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <div className="formContainer">
                            {/* <Form.Group size="lg" controlId="site">
                                <Form.Control
                                    autoFocus
                                    type="text"
                                    placeholder="Site"
                                    className="input"
                                    value={site}
                                    onChange={(e) => setSite(e.target.value)}
                                />
                                <FloatingLabel controlId="floatingSelectGrid" label="Domain">
                                    <Form.Select onChange={(e) => setDomain(e.target.value)}>
                                        <option value=".jrni.com">.jrni.com</option>
                                        <option value=".bookingbug.com">.bookingbug.com</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Form.Group>
                            <div className="margin-0-5em"></div> */}
                            <Form.Group size="lg" controlId="email">
                                <Form.Control
                                    autoFocus
                                    type="email"
                                    placeholder="Email"
                                    className="input"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
                            <div className="margin-0-5em"></div>
                            <Form.Group size="lg" controlId="password">
                                <Form.Control
                                    className="input"
                                    placeholder="Password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Group>
                            <div className="margin-1em"></div>
                            <span className="boldLink">Forget your password?</span>
                            <div className="margin-1-9em"></div>
                            {error && <p className="error">{error}</p>}
                            <div className="margin-1-9em"></div>
                            <button className="submitButton" type="submit" variant="submitButton" disabled={!validateForm() || loading}>Signin</button>
                            <div className="margin-1em"></div>
                        </div>
                    </Form>
                </div>
            </div>

        </div>
    );
}