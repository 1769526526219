import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { UserContext } from '../context/UserContext';

function Settings() {
    const history = useHistory();
    const {loggedIn, setLoggedIn} = useContext(UserContext);

    if (!loggedIn) {
        let path = "/" + sessionStorage.customer + "/login";
        history.push(path);
    }

    return (
        <div>
            <h1>Settings page</h1>
        </div>
    );
}

export default Settings;