import { getAuthKey } from "../Utils/Common";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { useState, useEffect, useContext } from "react";
import { SiteContext } from "../context/SiteContext";

export const GetDepartments = () => {

    const { url } = useContext(SiteContext);

    const history = useHistory();

    const [departments, setDepartments] = useState("");

    const logout = () => {
        console.log('logout' );
        localStorage.clear();
        setDepartments("");
        history.push('/:customer/login');
    }

    useEffect(async () => {
        getData();
        return () => {
            setDepartments();
        }
    }, [url]);

    async function getData() {

        try {
            //header
            let headers = {
                "Content-Type": "application/json",
                "App-Id": "f6b16c23",
                "App-Key": "f0bc4f65f4fbfe7b4b3b7264b655f5eb",
                "Auth-Token": getAuthKey()
            };

            const result = await axios.get(url + "/login/admin", {

                headers: headers

            });
            return setDepartments(result.data._embedded.administrators);
        } catch (error) {
            console.log('getDepartments', error);
            logout();
        }
    }

    return departments;

}

export const GetLocations = (department) => {

    const { url } = useContext(SiteContext);

    const [locations, setLocations] = useState("");

    useEffect(() => {
        getData();
        return () => {
            setLocations();
        }
    }, [url]);

    async function getData() {
        //header
        let headers = {
            "Content-Type": "application/json",
            "App-Id": "f6b16c23",
            "App-Key": "f0bc4f65f4fbfe7b4b3b7264b655f5eb",
            "Auth-Token": getAuthKey()
        };

        const result = await axios.get(url + "/company/" + department.company_id + "/children", {
            headers: headers
        });

        if (result.status && result.status === 200) {
            return setLocations(result.data._embedded.companies)
        } else {
            // console.log('getLocations', result.status);
        }
    }
    return locations;

}