import React from 'react';
import * as IconName from "react-icons/fc";
import { Link } from 'react-router-dom'; 
import { setCurrentPage } from '../../Utils/Common'

import './SideMenu.css';

const location = sessionStorage.getItem('companyName');

function change (page)  {
    setCurrentPage(page);
}

function SideMenu() {
    return <>
        {/* <div className='side-menu'>
            <Link to='#' className='menu-bars'>
                <IconName.FcBarChart/>
            </Link>
        </div> */}
        <nav className='nav-menu'>
            <ul className='nav-menu-items'>
            <div className='logo'>
                <h4>{location} Checkin</h4>
            </div>
                <li className='navbar'>
                    <Link to="/locations"  className='linkItems' onClick={() => change('locations')}>
                        <IconName.FcDepartment/>
                        <span>Locations</span>
                    </Link>
                </li>
                <li className='navbar'>
                    <Link to="/settings" className='linkItems' onClick={() => change('settings')}>
                        <IconName.FcAutomatic/>
                        <span>Settings</span>
                    </Link>
                </li>
                <li className='navbar'>
                    <Link to='#' className='linkItems'>
                        <IconName.FcComments/>
                        <span>Contact</span>
                    </Link>
                </li>
            </ul>
        </nav>
    </>
}

export default SideMenu;
