import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { Nav, Navbar, NavbarBrand } from 'react-bootstrap';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import { useHistory, Link } from "react-router-dom";
import './Header.css'
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { CurrentPageContext } from "../context/CurrentPageContext";
import { setCurrentPage } from "../Utils/Common";
import { DepartmentContext } from "../context/DepartmentContext";
import { UserInfoContext } from "../context/UserInfoContext";

const Header = () => {

    const { loggedIn, setLoggedIn } = useContext(UserContext);
    const { selectedDepartment, setSelectedDepartment } = useContext(DepartmentContext);
    const { userInfo, setUserInfo } = useContext(UserInfoContext);

    const history = useHistory();

    const logout = () => {
        let customer = sessionStorage.customer;
        localStorage.clear();
        sessionStorage.clear();
        setSelectedDepartment("");
        setLoggedIn("");
        
        sessionStorage.setItem("customer", customer);
        history.push("/" + customer);
    }

    function change(page) {
        setCurrentPage(page);
    }

    return <div style={{float: 'right'}}>
        <Navbar className="header" sticky="top" expand="lg">
            <Button className="logoutButton" onClick={logout}>Logout</Button>
            <Form className="searchForm">
                <Form.Group className="mb-3" controlId="search">
                    <Form.Control type="text" placeholder="Search..." />
                </Form.Group>
            </Form>
            <h5 className="companyName">{userInfo && 'Logged in as ' + `${userInfo.name}`}</h5>

        </Navbar>

    </div>
}


export default Header;