import React, { useMemo, useContext, useState, useEffect, useRef, Component } from "react";
import moment from 'moment';
import { Stack, Spinner, Button } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import { PersonCheck, PersonDash, PersonX, Camera, CardText, Save, Save2 } from 'react-bootstrap-icons';
import { SettingsContext } from "../../context/SettingsContext";
import axios from 'axios';

class TakePicture extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cameraOpen: false,
            uploaded: false,
            hasPhoto: false,
            stream: null,
            videoRef: React.createRef(),
            photoRef: React.createRef(),
            ctxValue: null
        }
    }

    openCamera = () => {
        this.getUserCamera();
        this.setState({ cameraOpen: true });
        this.setState({ uploaded: false });
        this.setState({ hasPhoto: false });
    }

    closeCamera = () => {
        this.state.stream.getTracks().forEach((track) => track.stop());
        this.state.stream.getVideoTracks()[0].enabled = false;
        this.setState({ cameraOpen: false });
        this.setState({ hasPhoto: false });
    }

    getUserCamera = () => {
        navigator.mediaDevices
            .getUserMedia({
                video: true
            })
            .then((stream) => {
                let video = this.state.videoRef.current;
                video.srcObject = stream;
                video.play();
                this.setState({ stream: stream });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    takePicture = () => {
        const width = 414;
        const height = 300;

        let video = this.state.videoRef.current

        let photo = this.state.photoRef.current

        photo.width = width

        photo.height = height

        let ctx = photo.getContext('2d')

        const xt = ctx.drawImage(video, 0, 0, width, height)

        this.setState({ ctx: ctx });
        this.setState({ hasPhoto: true });

    }

    clearImage = () => {
        let photo = this.state.photoRef.current

        let ctx = photo.getContext('2d')

        ctx.clearRect(0, 0, photo.width, photo.height)
        this.setState({ hasPhoto: false });
    }

    async uploadImage() {

        var dataURL = this.state.photoRef.current.toDataURL("image/jpeg");

        const blob = await (await fetch(dataURL)).blob();

        const file = new File([blob], 'fileName.jpeg', { type: "image/jpeg", lastModified: new Date() });

        const requestBody = {
            "image": dataURL,
            "filename": this.props.obj.booking_ref,
            "extension": ".png",
            "company": "reading",
        };

        //header
        let requestConfig = {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": "r1OK0DiEqb8CrrGI6Mjm56qXe9oblUx27sA5olIY",
            }
        };

        const result = await axios.post('https://ffs4ovo9dg.execute-api.eu-west-1.amazonaws.com/prod/uploadimage',
            requestBody, requestConfig).then((response) => {
                if (response.status === 200) {
                    this.setState({ uploaded: true });
                    this.closeCamera();
                }
            }).catch((requestError) => {
                console.log("error", requestError);
            })
    }

    render() {
        if (this.state.cameraOpen) {
            return <>
                <video className={this.state.hasPhoto ? 'hide' : 'm-1'} ref={this.state.videoRef}></video>
                <canvas className={this.state.hasPhoto ? 'm-1 canvas' : 'hide'} ref={this.state.photoRef}></canvas>
                <Stack gap={2}>
                    {!this.state.hasPhoto &&
                        <>
                            <Button onClick={this.takePicture}><Camera size={25} className="ms-1"></Camera></Button>
                            <Button className="m-1" variant="danger" onClick={this.closeCamera}>Close</Button>
                        </>
                    }
                    {this.state.hasPhoto &&
                        <>
                            <Button onClick={() => { this.uploadImage(); }} variant="success">Upload</Button>                            
                            <Button onClick={this.clearImage} variant="danger">Clear</Button>
                        </>
                    }

                </Stack>
            </>
        } else {

            return (
                <Button className="table-button" onClick={() => { this.openCamera(); }}><label className="btn-text">Camera</label> <Camera size={25} className="ms-1" /></Button>
            )
        }
    }
}

export default TakePicture;

// let videoRef = useRef(null);
// let photoRef = useRef(null);
// const [hasPhoto, setHasPhoto] = useState(false);
// const [cameraOpen, setCameraOpen] = useState(false);
// const [uploaded, setUploaded] = useState(false);
// // controls the current stream value
// const [stream, setStream] = useState(null);
// const [ctxValue, setCtxValue] = useState(null);


// const takePicture = () => {
//     const width = 414;
//     const height = 300;

//     let video = videoRef.current

//     let photo = photoRef.current

//     photo.width = width

//     photo.height = height

//     let ctx = photo.getContext('2d')

//     const xt = ctx.drawImage(video, 0, 0, width, height)

//     setCtxValue(xt);
//     setHasPhoto(true);

// }

// const clearImage = () => {
//     let photo = photoRef.current

//     let ctx = photo.getContext('2d')

//     ctx.clearRect(0, 0, photo.width, photo.height)
//     setHasPhoto(false);
// }

// // useEffect(() => {
// //     getUserCamera();
// // }, [videoRef]);

// const openCamera = () => {
//     getUserCamera();
//     setCameraOpen(true);
//     setUploaded(false);
//     setHasPhoto(false);
// }
// const closeCamera = () => {
//     stream.getTracks().forEach((track) => track.stop());
//     stream.getVideoTracks()[0].enabled = false;
//     setCameraOpen(false);
//     setHasPhoto(false);
// }

// async function uploadImage(obj) {

//     var dataURL = photoRef.current.toDataURL("image/jpeg");

//     const blob = await (await fetch(dataURL)).blob();

//     const file = new File([blob], 'fileName.jpeg', { type: "image/jpeg", lastModified: new Date() });

//     const requestBody = {
//         "image": dataURL,
//         "filename": obj.booking_ref,
//         "extension": ".png",
//         "company": "reading",
//     };

//     //header
//     let requestConfig = {
//         headers: {
//             "Content-Type": "application/json",
//             "x-api-key": "r1OK0DiEqb8CrrGI6Mjm56qXe9oblUx27sA5olIY",
//         }
//     };

//     const result = await axios.post('https://ffs4ovo9dg.execute-api.eu-west-1.amazonaws.com/prod/uploadimage',
//         requestBody, requestConfig).then((response) => {
//             if (response.status === 200) {
//                 setUploaded(true);
//                 closeCamera();
//             }
//         }).catch((requestError) => {
//             console.log("error", requestError);
//         })
// }