export const getUser = () => {
    const user = localStorage.getItem('login');
    if (user) {
        return JSON.parse(user);
    } else {
        return null;
    }
}
export const getAuthKey = () => {
    const authKey = sessionStorage.getItem('authKey');
    if (authKey) {
        return JSON.parse(authKey);
    } else {
        return null;
    }
}
export const setUserLogin = (authKey, user, apiUrl) => {
    localStorage.setItem('login', JSON.stringify(user));
    sessionStorage.setItem('authKey', JSON.stringify(authKey));
    localStorage.setItem('apiUrl', JSON.stringify(apiUrl));
}
export const setCurrentPage = (page) => {
    localStorage.setItem('currentPage', page);
}
export const getCurrentPage = () => {
    return localStorage.getItem('currentPage');
}
export const removeUser = () => {
    localStorage.removeItem('login');
}