import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { GetDepartments } from '../api/ApiComponent';
import { UserContext } from '../context/UserContext';
import Department from '../components/Department';
import { DepartmentContext } from '../context/DepartmentContext';


function Departments() {
    const history = useHistory();
    const { loggedIn, setLoggedIn } = useContext(UserContext);
    const { selectedDepartment, setSelectedDepartment} = useContext(DepartmentContext);

    if (!loggedIn) {
        let path = `/login`;
        history.push(path);
    }
    
    const routeChange = (page) => {
        let path = `/`+page;
        history.push(path);
    }

    const parentDepartments = GetDepartments();
    const result = Object.values(parentDepartments);

    if (result.length === 1) {
        updateSelectedDepartment(result[0]);
    }

    function updateSelectedDepartment(selected){
        setSelectedDepartment(selected);
        routeChange('locations');
    }

    return (<div>

        {result.length > 0 &&
            (result.map((department) => {

                return <Department key={department.company_id}
                    name={department.company_name}
                    id={department.company_id}
                    setSelected={updateSelectedDepartment}>
                </Department>
            }

            ))

        }


    </div>)


}

export default Departments;