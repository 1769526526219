import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { GetLocations } from '../api/ApiComponent';
import { UserContext } from '../context/UserContext';
import { DepartmentContext } from '../context/DepartmentContext';
import { LocationContext } from '../context/LocationContext';
import Location from '../components/Location';


function Locations(props) {
    const history = useHistory();
    const { loggedIn, setLoggedIn } = useContext(UserContext);
    const { selectedLocation, setSelectedLocation } = useContext(LocationContext);

    const department = useContext(DepartmentContext);

    const locations = GetLocations(department.selectedDepartment);

    const result = Object.values(locations);

    if (!loggedIn) {
        let path = "/" + sessionStorage.customer + "/login";
        history.push(path);
    }

    const routeChange = (page) => {
        let path = `/` + page;
        history.push(path);
    }

    function updateSelectedLocation(selected) {
        setSelectedLocation(selected);
        routeChange('bookings');
    }


    return (
        <div>

            {result.length > 1 &&
                (result.map((department) => {

                    return <Location key={department.id}
                        name={department.name}
                        id={department.id}
                        setSelected={updateSelectedLocation}>
                    </Location>
                }

                ))

            }


        </div>
    )

}

export default Locations;