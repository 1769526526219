import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { UserContext } from '../context/UserContext';
import { Stack, Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { ArrowRepeat } from 'react-bootstrap-icons';
import { SiteContext } from "../context/SiteContext";
import { SettingsContext } from "../context/SettingsContext";

import { LocationContext } from '../context/LocationContext';
import { AdminUserContext } from '../context/AdminUserContext';
import { UserInfoContext } from "../context/UserInfoContext";
import Booking from '../components/BookingsTable/Booking';
import Service from '../api/ApiService';
import './Bookings.css';
import { setUserLogin, setCurrentPage as setDefaultPage, getCurrentPage } from "../Utils/Common";
import axios from 'axios';
import moment from 'moment';


function Bookings(props) {

    const history = useHistory();
    const { selectedLocation, setSelectedLocation } = useContext(LocationContext);
    const { url } = useContext(SiteContext);
    const [reloadBookings, setReloadBookings] = useState([]);
    const [status, setStatus] = useState("DUE");
    const [myInterval, setMyInterval] = useState(0);
    const [shouldIntervalBeCancelled, setShouldIntervalBeCancelled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [arrivedLoading, setArrivedLoading] = useState(false);
    const [rejectedLoading, setRejectedLoading] = useState(false);
    const [arrivedAndPaidLoading, setArrivedAndPaidLoading] = useState(false);
    const [noShowLoading, setNoShowLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [markNoShowLoading, setMarkNoShowLoading] = useState(false);
    const { adminUser, setAdminUser } = useContext(AdminUserContext);
    const { userInfo, setUserInfo } = useContext(UserInfoContext);
    const { loggedIn, setLoggedIn } = useContext(UserContext);
    const { setSettings } = useContext(SettingsContext);
    const [tabs, setTabs] = useState({});

    // No bookings related
    const [reg, setReg] = useState("");
    const [diyWaste, setDiyWaste] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submittedResult, setSubmittedResult] = useState("Submitted");


    const service = new Service();
    service.setUrl(url);

    let locationId = selectedLocation;

    let result = [];

    let watcherSet = false;

    // let tabs = {};

    useEffect(() => {
        let authKey = sessionStorage.getItem('authKey');
        if (authKey) {
            setLoggedIn(authKey);
            setDefaultPage('bookings');

            const loggedin = JSON.parse(localStorage.getItem('login'));
            const user = loggedin.administrators[0]
            const role = user.role;
            setUserInfo(user);
            if (role === "owner") {

                const admin = {
                    role: role,
                    info: user
                }

                setAdminUser(admin);
            } else {
                setAdminUser({ info: user });
                setSelectedLocation(user.company_id);
            }
            getSettings();
            getBookings(user.company_id, false, true);
            // setShouldIntervalBeCancelled(true);
        } else {
            if (!loggedIn) {
                let path = "/" + sessionStorage.customer + "/login";
                history.push(path);
            }
        }
        return () => {
            clearInterval(myInterval);
        }
    }, [loggedIn])

    // For starting the interval ->
    useEffect(() => { // when status updates,we need to make a new interval that uses the new value (as intervals do not react to state changes)
        clearInterval(myInterval);
        // setShouldIntervalBeCancelled(true);
        activityWatcher()
        return () => {
            clearInterval(myInterval);
        }
    }, [status]);

    useEffect(() => {
        if (shouldIntervalBeCancelled) {
            clearInterval(myInterval);     // this being inside a useEffect makes sure that it gets the fresh value of state
        }
        return () => {
            clearInterval(myInterval);
        }
    }, [shouldIntervalBeCancelled]);

    function activityWatcher() {
        // source: https://thisinterestsme.com/javascript-detect-user-activity/
        var secondsSinceLastActivity = 0;
        var maxInactivity = 60;
        //Setup the setInterval method to run
        //every second. 1000 milliseconds = 1 second.
        const loggedin = JSON.parse(localStorage.getItem('login'));
        const user = loggedin.administrators[0]
        if (!user.company_id) return;
        var newInterval = setInterval(function () {
            secondsSinceLastActivity++;
            //if user idle for more than maxInactivity seconds, auto refresh the data
            // we can more or less grantee that the data is up to date on the back end 
            if (secondsSinceLastActivity > maxInactivity) {
                getBookings(user.company_id, false, false);
                secondsSinceLastActivity = 0
            }
        }, 1000);
        setMyInterval(newInterval)
        //The function that will be called whenever a user is active
        function activity() {
            //reset the secondsSinceLastActivity variable
            //back to 0
            secondsSinceLastActivity = 0;
        }

        //An array of DOM events that should be interpreted as
        //user activity.
        var activityEvents = [
            'mousedown', 'mousemove', 'keydown',
            'scroll', 'touchstart'
        ];

        //add these events to the document.
        //register the activity function as the listener parameter.
        activityEvents.forEach(function (eventName) {
            document.addEventListener(eventName, activity, true);
        });
    }

    async function getBookings(id, myStatus, showLoading) {
        if (showLoading) {
            setLoading(true);
        }
        if (!id) {
            id = locationId;
        }
        if (!myStatus) {
            myStatus = status;
        } else {
            // setStatus(myStatus);
            // refreshInterval(myStatus)
        }
        let records = null;
        if (myStatus === 'NOBOOKING') {
            const isStaging = window.location.pathname.includes("staging") ? true : false;
            records = await service.getNoBooking(id, moment()
                .locale('en')
                .format('YYYY-MM-DD'), isStaging);
            records = records.sort((a, b) => {
                return moment(a.date + " " + a.time,"YYYY-MM-DD HH:mm") - moment(b.date + " " + b.time,"YYYY-MM-DD HH:mm")
            });
        } else {
            records = await service.getBooking(id);
        }
        if (records != null) {
            result = Object.values(records);
            setReloadBookings(result);
            if (showLoading) {
                setLoading(false);
            }
        }
    }

    const routeChange = (page) => {
        let path = `/` + page;
        history.push(path);
    }

    async function UpdateArrived(obj,setQ) {
        setArrivedLoading(true);
        const updated = await service.markArrived(locationId, obj.booking_ref, obj.setting);
        // remove from array for quicker response
        // result = reloadBookings.filter(booking => booking.id != obj.booking_ref);
        for (let i in reloadBookings) { // manual update of local data
            if (reloadBookings[i].id === updated.data.id) {
                reloadBookings[i] = updated.data
            }
        }
        setReloadBookings(reloadBookings);

        // refresh the data in the background
        //    getBookings(null, "", false);
        setArrivedLoading(false);
        setQ("")
    }

    async function UpdateRejected(obj,setQ) {
        setRejectedLoading(true);
        const updated = await service.markRejected(locationId, obj.booking_ref, obj.setting);
        // remove from array for quicker response
        // result = reloadBookings.filter(booking => booking.id != obj.booking_ref);
        for (let i in reloadBookings) { // manual update of local data
            if (reloadBookings[i].id === updated.data.id) {
                reloadBookings[i] = updated.data
            }
        }
        setReloadBookings(reloadBookings);

        // refresh the data in the background
        // getBookings(null, "", false);

        setRejectedLoading(false);
        setQ("")
    }

    async function UpdateArrivedAndPaid(obj,setQ) {
        setArrivedAndPaidLoading(true);
        const updated = await service.markArrivedAndPaid(locationId, obj.booking_ref, obj.setting);
        // remove from array for quicker response
        // result = reloadBookings.filter(booking => booking.id != obj.booking_ref);
        for (let i in reloadBookings) { // manual update of local data
            if (reloadBookings[i].id === updated.data.id) {
                reloadBookings[i] = updated.data
            }
        }
        setReloadBookings(reloadBookings);

        // refresh the data in the background
        // getBookings(null, "", false);

        setArrivedAndPaidLoading(false);
        setQ("")
    }

    async function UpdateNoShow(obj,setQ) {
        setNoShowLoading(true);
        const updated = await service.markNoShow(locationId, obj.booking_ref, obj.setting);
        for (let i in reloadBookings) { // manual update of local data
            if (reloadBookings[i].id === updated.data.id) {
                reloadBookings[i] = updated.data
            }
        }
        setReloadBookings(reloadBookings);

        // refresh the data in the background
        // getBookings(null, "", false);
        setNoShowLoading(false);
        setQ("")
    }

    async function UpdateRemoveStatus(obj,setQ) {
        setRemoveLoading(true);
        const updated = await service.removeStatus(locationId, obj.booking_ref);
        // remove from array for quicker response
        // result = reloadBookings.filter(booking => booking.id != obj.booking_ref);
        for (let i in reloadBookings) {  // manual update of local data
            if (reloadBookings[i].id === updated.data.id) {
                reloadBookings[i] = updated.data
            }
        }
        setReloadBookings(reloadBookings);

        // refresh the data in the background
        // getBookings(null, "", false);
        setRemoveLoading(false);
        setQ("")
    }

    async function markAsNoShow(setQ) {
        setMarkNoShowLoading(true)
        // work out no show bookings
        for (const record of reloadBookings) {
            // for not checked in and not marked as no show bookings only
            if (record.multi_status.checked_in === undefined && record.multi_status.no_show === undefined) {
                const updated = await service.markNoShow(locationId, record.id, record.settings);
                for (let i in reloadBookings) {
                    if (reloadBookings[i].id === updated.data.id) {
                        reloadBookings[i] = updated.data
                    }
                }
            }

        }

        const loggedin = JSON.parse(localStorage.getItem('login'));
        const user = loggedin.administrators[0];
        setReloadBookings(reloadBookings);
        // getBookings(user.company_id, "", true);
        setMarkNoShowLoading(false);
        setQ("")
    }

    async function UpdateNotes(obj, notes) {
        await service.updateNotes(locationId, obj.booking_ref, notes);
        getBookings(null, false, false);
    }

    function SelectStatus(value) {
        if (value === "NOBOOKING" || status === "NOBOOKING") {
            setStatus(value);
            setLoading(true);
            getBookings(undefined, value, true);
            setShouldIntervalBeCancelled(true);
        } else {
            setStatus(value);
            setShouldIntervalBeCancelled(false);
        }
        // refreshInterval(value)
        // setShouldIntervalBeCancelled(true);
    }

    function updateReg(e) {

        setSubmitted(false);
        const validEmail = new RegExp('^[a-zA-Z0-9]*$');

        if (validEmail.test(e.target.value)) {
            // this.setState({ birthDate: e.target.value })
            setReg(e.target.value.toUpperCase());
        }
    }

    function updateDIYWaste(e) {
        setSubmitted(false);

        setDiyWaste(true);
    }

    async function Submit() {
        if (reg !== "") {
            // do the thing
            setSubmitted(false);
            const isStaging = window.location.pathname.includes("staging") ? true : false;
            const resultSuccess = await service.postNoBooking(userInfo.company_id, userInfo.company_name, reg, diyWaste, isStaging);
            if (resultSuccess.data === "Success") {
                setSubmittedResult(reg + " Added")
            } else {
                if(resultSuccess.data){
                    console.log("resultSuccess not success: ", resultSuccess)
                    setSubmittedResult("Failed: ", resultSuccess.data)
                } else {
                    setSubmittedResult("Failed")
                }
            }
            setSubmitted(true);
            setReg("");
            setDiyWaste(false);
            getBookings(null, "NOBOOKING", true);

        } else {
            console.log("failed to add no bookings record");
            setSubmitted(false);
        }
    }

    async function getSettings() {

        const company = sessionStorage.companyName;

        const requestBody = {
            company: company,
            type: "tab",
            setting: "due"
        };

        //header
        let requestConfig = {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": "r1OK0DiEqb8CrrGI6Mjm56qXe9oblUx27sA5olIY",
            }
        };

        // const result = await axios.post('https://ffs4ovo9dg.execute-api.eu-west-1.amazonaws.com/prod/settingsById',
        await axios.post('https://ffs4ovo9dg.execute-api.eu-west-1.amazonaws.com/prod/settingsByType',
            requestBody, requestConfig).then((response) => {
                setSettings(response.data);
                let tabs = {}
                response.data.forEach(tab => {
                    const key = Object.keys(tab)[0];
                    tabs[key] = true;
                });
                setTabs(tabs);
            }).catch((requestError) => {
                console.log("error", requestError);
            })

    }

    return (
        <div>
            <Container style={{ alignItems: 'start' }}>

                {adminUser && !adminUser.role &&
                    <Row>
                        <Col >
                            {userInfo.company_name}
                        </Col >
                    </Row>
                }

            </Container>
            {!(status === 'NOBOOKING') ? <Container></Container> :
                <Container>
                    {/* <label for="searchtime">Search date and time:</label>
                    <input type="datetime-local" id="searchtime" name="searchtime" value={datetime} onChange={(e) => { updateDate(e) }}></input> */}
                    <div style={{ margin: 'auto' }}>
                        <Stack direction="horizontal" gap={2}>

                            <label>Vehicle reg</label>
                            <input type="text" id="fname" name="fname" value={reg} onChange={(e) => { updateReg(e) }}></input>&nbsp;&nbsp;<label>DIY Waste? <input type="checkbox" id="diy_waste" name="diy_waste" checked={diyWaste} onChange={(e) => { updateDIYWaste(e) }}/></label>&nbsp;&nbsp;
                            <Button variant='primary' onClick={() => { Submit() }}>Submit</Button>
                            {!submitted ?
                                null
                                :
                                <div>{submittedResult}</div>
                            }
                        </Stack>
                    </div>
                </Container>
            }
            <Container style={{ flexFlow: 'row-reverse' }}>
                <Row>
                    <Col>
                        <Button style={{ width: '50px' }} variant='primary' onClick={() => { setLoading(true); getBookings(null, false, true) }}>
                            <ArrowRepeat size={25} />
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Container className={loggedIn && adminUser && adminUser.role ? "status-nav-bar" : "status-nav-bar1"} style={{ backgroundColor: 'white', padding: '5px', width: '800px', borderRadius: '.8em' }}>
                <Row>
                    <Stack direction="horizontal" gap={2}>
                        {tabs.due &&
                            <Button disabled={loading} style={{ width: '100px' }} variant={status === 'DUE' ? 'success' : 'secondary'} onClick={() => { SelectStatus("DUE"); }}>Due</Button>
                        }
                        {tabs.arrived &&
                            <Button disabled={loading} style={{ width: '100px' }} variant={status === 'ARRIVED' ? 'success' : 'secondary'} onClick={() => { SelectStatus("ARRIVED"); }}>Arrived</Button>
                        }
                        {tabs.rejected &&
                            <Button disabled={loading} style={{ width: '100px' }} variant={status === 'REJECTED' ? 'success' : 'secondary'} onClick={() => { SelectStatus("REJECTED"); }}>Rejected</Button>
                        }
                        {tabs.arrived_and_paid &&
                            <Button disabled={loading} style={{ width: '100px' }} variant={status === 'ARRIVEDANDPAID' ? 'success' : 'secondary'} onClick={() => { SelectStatus("ARRIVEDANDPAID"); }}>Paid</Button>
                        }
                        {tabs.noshow &&
                            <Button disabled={loading} style={{ width: '100px' }} variant={status === 'NOSHOW' ? 'success' : 'secondary'} onClick={() => { SelectStatus("NOSHOW"); }}>No Show</Button>
                        }
                        {tabs.noxbookings &&
                            <Button disabled={loading} style={{ width: '120px' }} variant={status === 'NOBOOKING' ? 'success' : 'secondary'} onClick={() => { SelectStatus("NOBOOKING"); }}>No booking</Button>
                        }
                    </Stack>
                </Row>
            </Container>
            {!loading ?
                <Booking reloadBookings={reloadBookings} updateArrived={UpdateArrived} updateRejected={UpdateRejected}
                    updateArrivedAndPaid={UpdateArrivedAndPaid} updateNoShow={UpdateNoShow}
                    updateRemoveStatus={UpdateRemoveStatus} updateNotes={UpdateNotes}
                    status={status}
                    arrivedLoading={arrivedLoading}
                    rejectedLoading={rejectedLoading}
                    arrivedAndPaidLoading={arrivedAndPaidLoading}
                    noShowLoading={noShowLoading}
                    removeLoading={removeLoading}
                    markAsNoShow={markAsNoShow}
                    markNoShowLoading={markNoShowLoading}
                    locationId={locationId}
                    getBookings={() => getBookings(null, false, true)}>

                </Booking>
                :
                <Container>
                    <Spinner animation="border" variant="primary" style={{ width: '3rem', height: '3rem', marginTop: "100px" }} />

                </Container>
            }
        </div>
    )

}

export default Bookings;