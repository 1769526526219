import React, { useMemo, useContext, useState, useEffect, useRef } from "react";
import moment from 'moment';
import { Stack, Spinner, Button } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import { PersonCheck, PersonDash, PersonX, Camera, CardText, Save, Save2 } from 'react-bootstrap-icons';
import Service from "../../api/ApiService";
import { Component } from "react";

class MakeNotes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            notesValue: "",
            makeNotes: false
        }
    }
    service = new Service();
    getNotes() {

        if (this.props.obj) {
            if (!this.state.makeNotes) {
                this.props.bookings.forEach(booking => {

                    if (booking.id == this.props.obj.booking_ref) {
                        this.setState({ notesValue: booking.private_note });
                    }
                })
            } else {
                this.setState({ notesValue: "" });
            }
        }

        this.state.makeNotes = !this.state.makeNotes;
    }

    setNotesValue(notes) {
        this.setState({ notesValue: notes });
    }

    async saveNotes() {
        await this.service.updateNotes(this.props.locationId, this.props.obj.booking_ref, this.state.notesValue);
        this.props.handleNotesChange();
    }
    render() {
        if (this.state.makeNotes) {

            return <>
                <textarea className="notes-textarea" placeholder="Type notes.." value={this.state.notesValue} onChange={event => this.setNotesValue(event.target.value)} rows="5"></textarea>

                <Stack gap={2}>

                    <Button className="table-button" onClick={() => { this.getNotes(); this.saveNotes() }}><label className="btn-text">Save</label> <Save2 size={20} className="ms-1" /></Button>
                    <Button className="table-button" variant="danger" onClick={() => { this.getNotes(); }}><label className="btn-text">Close</label></Button>

                </Stack>
            </>

        } else {

            return (
                <Button className="table-button" onClick={() => { this.getNotes() }}><label className="btn-text">Notes</label> <CardText size={25} className="ms-1" /></Button>
            )
        }
    }

}

export default MakeNotes;