import React from 'react';
import '../Common.css';

const NotFoundPage = () => {
    return (
        <div className="display-flex mt-5">
            <h2>We cannot find a company to load. If you wish to use the bookinglab check-in app, please contact your Account Manager.</h2>            
        </div>
    );
};

export default NotFoundPage;