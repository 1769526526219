import { Route, Switch, Redirect, BrowserRouter as Router, } from 'react-router-dom';
import { useMemo, useState } from 'react';
import Login from './pages/Login';
import Locations from './pages/Locations';
import Settings from './pages/Settings';
import NotFoundPage from './pages/404';
import { UserContext } from './context/UserContext';
import { CurrentPageContext } from './context/CurrentPageContext';
import Header from './components/Header';
import Departments from './pages/Departments';
import { DepartmentContext } from './context/DepartmentContext';
import { LocationContext } from './context/LocationContext';
import { SettingsContext } from './context/SettingsContext';
import { AdminUserContext } from './context/AdminUserContext';
import { UserInfoContext } from './context/UserInfoContext';
import Bookings from './pages/Bookings';
import { SiteContext } from './context/SiteContext';
import SideMenu from './components/menu/SideMenu';
import { getUser } from './Utils/Common';
import logo from './images/bookinglabLogo.png'

var classNames = require('classnames');

function App() {

  const [loggedIn, setLoggedIn] = useState();
  const providerValue = useMemo(() => ({ loggedIn, setLoggedIn }), [loggedIn, setLoggedIn]);

  const [userInfo, setUserInfo] = useState();
  const userInfoProviderValue = useMemo(() => ({ userInfo, setUserInfo }), [userInfo, setUserInfo]);

  const [adminUser, setAdminUser] = useState();
  const adminUserProviderValue = useMemo(() => ({ adminUser, setAdminUser }), [adminUser, setAdminUser]);

  const [currentPage, setCurrentPage] = useState();
  const currentPageProviderValue = useMemo(() => ({ currentPage, setCurrentPage }), [currentPage, setCurrentPage]);

  const [selectedDepartment, setSelectedDepartment] = useState();
  const selectedDepartmentProviderValue = useMemo(() => ({ selectedDepartment, setSelectedDepartment }), [selectedDepartment, setSelectedDepartment]);

  const [selectedLocation, setSelectedLocation] = useState();
  const selectedLocationProviderValue = useMemo(() => ({ selectedLocation, setSelectedLocation }), [selectedLocation, setSelectedLocation]);

  const [url, setUrl] = useState();
  const urlProviderValue = useMemo(() => ({ url, setUrl }), [url, setUrl]);

  const [settings, setSettings] = useState();
  const settingsProviderValue = useMemo(() => ({ settings, setSettings }), [settings, setSettings]);


  var liClasses = classNames({
    'grid-content': adminUser && adminUser.role,
    'grid-content-alt': !adminUser,
    'content-box': loggedIn,
    'content-box-alt': !loggedIn,
  });

  return (
    <div className={loggedIn && adminUser && adminUser.role ? 'grid-container' : loggedIn && 'grid-container-alt'}>

      <UserContext.Provider value={providerValue}>
        <UserInfoContext.Provider value={userInfoProviderValue}>
          <AdminUserContext.Provider value={adminUserProviderValue}>
            <SiteContext.Provider value={urlProviderValue}>
              <CurrentPageContext.Provider value={currentPageProviderValue}>
                <DepartmentContext.Provider value={selectedDepartmentProviderValue}>
                  <LocationContext.Provider value={selectedLocationProviderValue}>
                    <SettingsContext.Provider value={settingsProviderValue}>

                      {loggedIn &&
                        <div className="wrapper">
                          <div className="box a">
                            <img src={logo}></img></div>
                          <div className="box b">
                            <Header></Header>
                          </div>
                        </div>
                      }
                      {loggedIn && adminUser && adminUser.role &&
                        <div className='grid-nav'>
                          <SideMenu></SideMenu>
                        </div>
                      }
                      <div className={loggedIn && adminUser && adminUser.role ? 'grid-content' : 'grid-content-alt', !loggedIn ? 'content-box-alt' : 'content-box'}>
                        <Switch>
                          <Router basename="/">

                            <Route path='/:customer?' exact>
                              <Login></Login>
                            </Route>
                            <Route path='/:customer?/login'>
                              <Login></Login>
                            </Route>
                            <Route path='/:customer?/departments' >
                              <Departments></Departments>
                            </Route>
                            <Route path='/:customer?/locations'>
                              <Locations></Locations>
                            </Route>
                            <Route path='/:customer?/bookings'>
                              <Bookings></Bookings>
                            </Route>
                            <Route path='/:customer?/settings'>
                              <Settings></Settings>
                            </Route>
                            <Route path="/:customer?/404">
                                <NotFoundPage></NotFoundPage>
                            </Route>
                        {/* <Redirect to="/404"></Redirect> */}
                          </Router>
                        </Switch>

                      </div>

                    </SettingsContext.Provider>
                  </LocationContext.Provider>
                </DepartmentContext.Provider>
              </CurrentPageContext.Provider>
            </SiteContext.Provider>
          </AdminUserContext.Provider>
        </UserInfoContext.Provider>
      </UserContext.Provider>

    </div>
  );
}

export default App;
