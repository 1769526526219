import { getAuthKey } from "../Utils/Common";

import axios from 'axios';


class Service {

    clients = [];

    constructor() {
        this.url = sessionStorage.getItem('apiUrl');
        let today = new Date();
        let month = today.getMonth() + 1;
        let day = today.getDate();
        // format is yyyy-MM-dd, so we need to add the leading 0 to months/days that are only one digit long
        if (month < 10) {
            month = "0" + month.toString()
        }
        if (day < 10) {
            day = "0" + day.toString()
        }
        this.date = today.getFullYear() + '-' + month + '-' + day;
    }

    setUrl = function (url) {
        this.url = sessionStorage.getItem('apiUrl');
    }

    async getBooking(locationId) {

        if (this.url === undefined) {
            this.url = sessionStorage.getItem('apiUrl');
        }

        //header
        const headers = {
            "Content-Type": "application/json",
            "App-Id": "f6b16c23",
            "App-Key": "f0bc4f65f4fbfe7b4b3b7264b655f5eb",
            "Auth-Token": getAuthKey()
        };

        let bookings;

        try {
            const data = {
                start_date: this.date, end_date: this.date, page: 1, per_page: 1000
            }
            let parsedBookings = []

            const elasticSearch = {
                "from": 0,
                "size": 9800,
                "query": {
                    "bool": {
                        "must": [
                            {
                                "match": {
                                    "company_id": locationId
                                }
                            },
                            {
                                "range": {
                                    "just_date": {
                                        "gte": `${this.date}`,
                                        "lte": `${this.date}`
                                    }
                                }
                            },
                            {
                                "match": {
                                    "is_cancelled": false
                                }
                            }
                        ],
                        "must_not": [
                            {
                                "bool": {
                                    "must": [
                                        { "match": { "s_status": "Blocked" } }
                                    ]
                                }
                            }
                        ]
                    }
                },
                "sort": {
                    "datetime": "desc"
                }
            }

            const result = await axios.post(this.url + "/admin/search/booking",
                elasticSearch,
                {
                    headers: headers
                },);

            bookings = result.data.hits.hits;

            // get all clients first
            for (const booking of bookings) {
                if (!this.clients.find((id) => id === booking._source.member_id)) {
                    this.clients.push(booking._source.member_id);
                }
            }

            // now build elasticsearch for members
            const search = {
                "from": 0,
                "size": 9800,
                query: {
                    bool: {should: []}
                },
                "sort": {
                    "id": "asc"
                }
            };

            if (this.clients.length > 0) {
                for (const client of this.clients) {
                    search.query.bool.should.push({
                        match: {
                            id: client
                        }
                    });
                }
            }

            const foundUsers = await axios.post(this.url + "/admin/search/client", search, {
                headers: headers
            });
            const users = [];
            if (foundUsers.data.hits.total > 0) {
                for (const u of foundUsers.data.hits.hits) {
                    users.push(u._source);
                }
            }

            for (let i in bookings) {
                bookings[i]["_source"]["_embedded"] = {};
                parsedBookings.push(bookings[i]["_source"])
                // need to fix how questions (and answers) are stored so it matches the prev way
                delete parsedBookings[i].questions
                parsedBookings[i].questions = {}
                for (let j in parsedBookings[i].nested_questions) {
                    parsedBookings[i].questions[parsedBookings[i].nested_questions[j].id.toString()] = parsedBookings[i].nested_questions[j]
                }

                // now we find the member data from elasticsearch
                const foundClient = users.find(({id}) => {
                    return parsedBookings[i].member_id === id
                });
                if (foundClient) {
                    parsedBookings[i]["_embedded"] = { client: foundClient }
                }
            }
            // old network call backup
            // const result2 = await axios.get(this.url + "/admin/" + locationId + "/bookings", {
            //     headers: headers,
            //     params: data
            // });
            // parsedBookings = result2.data['_embedded'].bookings;
            return parsedBookings;
        } catch (error) {
            console.log('getBookings', error);
        }

        return bookings;
    }

    async getNoBooking(locationId, date, isStaging) {

        const headers = {
            "Content-Type": "application/json",
            "x-api-key": "r1OK0DiEqb8CrrGI6Mjm56qXe9oblUx27sA5olIY",
        }

        let bookings;

        try {
            // const data = {
            //     start_date: this.date, end_date: this.date, page: 1, per_page: 1000
            // }

            const result = await axios.get(`https://ffs4ovo9dg.execute-api.eu-west-1.amazonaws.com/prod/noBookings?company=${locationId}&date=${date}&staging=${isStaging}`, {
                headers: headers,
                // params: data

            });
            bookings = result.data;

            return bookings;
        } catch (error) {
            console.log('getBookings', error);
        }

        return bookings;
    }

    async postNoBooking(locationId, locationName, reg, diyWaste, isStaging) {

        const headers = {
            "Content-Type": "application/json",
            "x-api-key": "r1OK0DiEqb8CrrGI6Mjm56qXe9oblUx27sA5olIY",
        }

        const body = {
            "company": locationId.toString(),
            "siteName": locationName,
            "vehicleReg": reg,
            "diy_waste": diyWaste,
            "staging": isStaging
        }
        try {
            // const data = {
            //     start_date: this.date, end_date: this.date, page: 1, per_page: 1000
            // }

            const result = await axios.post(`https://ffs4ovo9dg.execute-api.eu-west-1.amazonaws.com/prod/noBookings`,
                body
                , {
                    headers: headers
                },);

            return result;
        } catch (error) {
            console.log('getBookings', error);
            return false;
        }

        return false;
    }

    async markArrived(locationId, bookingRef, settings) {

        if (this.url === undefined) {
            this.url = sessionStorage.getItem('apiUrl');
        }

        //header
        const headers = {
            "Content-Type": "application/json",
            "App-Id": "f6b16c23",
            "App-Key": "f0bc4f65f4fbfe7b4b3b7264b655f5eb",
            "Auth-Token": getAuthKey()
        };

        let arrived;

        try {

            const data = {
                current_multi_status: "checked_in",
                settings: settings,
                attended: true
            }

            const result = await axios.put(this.url + "/admin/" + locationId + "/bookings/" + bookingRef, data, {
                headers: headers
            })

            arrived = result;

            return arrived;

        } catch (error) {
            console.log('markArrived', error);

        }

        return arrived;

    }

    async markRejected(locationId, bookingRef, settings) {

        if (this.url === undefined) {
            this.url = sessionStorage.getItem('apiUrl');
        }

        //header
        const headers = {
            "Content-Type": "application/json",
            "App-Id": "f6b16c23",
            "App-Key": "f0bc4f65f4fbfe7b4b3b7264b655f5eb",
            "Auth-Token": getAuthKey()
        };

        let rejected;

        try {

            const data = {
                current_multi_status: "rejected",
                settings: settings,
                attended: true
            }

            const result = await axios.put(this.url + "/admin/" + locationId + "/bookings/" + bookingRef, data, {
                headers: headers
            })

            rejected = result;

            return rejected;

        } catch (error) {
            console.log('markRejected', error);

        }

        return rejected;

    }

    async markArrivedAndPaid(locationId, bookingRef, settings) {

        if (this.url === undefined) {
            this.url = sessionStorage.getItem('apiUrl');
        }

        //header
        const headers = {
            "Content-Type": "application/json",
            "App-Id": "f6b16c23",
            "App-Key": "f0bc4f65f4fbfe7b4b3b7264b655f5eb",
            "Auth-Token": getAuthKey()
        };

        let arrived_and_paid;

        try {

            const data = {
                current_multi_status: "arrived_and_paid",
                settings: settings,
                attended: true
            }

            const result = await axios.put(this.url + "/admin/" + locationId + "/bookings/" + bookingRef, data, {
                headers: headers
            })

            arrived_and_paid = result;

            return arrived_and_paid;

        } catch (error) {
            console.log('markArrivedAndPaid', error);

        }

        return arrived_and_paid;

    }

    async markNoShow(locationId, bookingRef, settings) {

        if (this.url === undefined) {
            this.url = sessionStorage.getItem('apiUrl');
        }

        //header
        const headers = {
            "Content-Type": "application/json",
            "App-Id": "f6b16c23",
            "App-Key": "f0bc4f65f4fbfe7b4b3b7264b655f5eb",
            "Auth-Token": getAuthKey()
        };

        let noShow;

        try {

            const data = {
                current_multi_status: "no_show",
                settings: settings,
                attended: false
            }

            const result = await axios.put(this.url + "/admin/" + locationId + "/bookings/" + bookingRef, data, {
                headers: headers
            })

            noShow = result;

            return noShow;

        } catch (error) {
            console.log('noShow', error);

        }

        return noShow;

    }

    async removeStatus(locationId, bookingRef) {

        if (this.url === undefined) {
            this.url = sessionStorage.getItem('apiUrl');
        }

        //header
        const headers = {
            "Content-Type": "application/json",
            "App-Id": "f6b16c23",
            "App-Key": "f0bc4f65f4fbfe7b4b3b7264b655f5eb",
            "Auth-Token": getAuthKey()
        };

        let removeStatus;

        try {

            const data = {
                settings: { "current_multi_stat": "", "multi_stats": {} }
            }

            const result = await axios.put(this.url + "/admin/" + locationId + "/bookings/" + bookingRef, data, {
                headers: headers
            })

            removeStatus = result;

            return removeStatus;

        } catch (error) {
            console.log('removeStatus', error);

        }

        return removeStatus;

    }

    async updateNotes(locationId, bookingRef, notes) {

        if (this.url === undefined) {
            this.url = sessionStorage.getItem('apiUrl');
        }

        //header
        const headers = {
            "Content-Type": "application/json",
            "App-Id": "f6b16c23",
            "App-Key": "f0bc4f65f4fbfe7b4b3b7264b655f5eb",
            "Auth-Token": getAuthKey()
        };

        let updatedNote;

        try {

            const data = {
                "private_note": notes
            }

            const result = await axios.put(this.url + "/admin/" + locationId + "/bookings/" + bookingRef, data, {
                headers: headers
            })

            updatedNote = result;

            return updatedNote;

        } catch (error) {
            console.log('updatedNote', error);

        }

        return updatedNote;

    }

    async getClient(locationId, clientId) {
        if (this.url === undefined) {
            this.url = sessionStorage.getItem('apiUrl');
        }

        //header
        const headers = {
            "Content-Type": "application/json",
            "App-Id": "f6b16c23",
            "App-Key": "f0bc4f65f4fbfe7b4b3b7264b655f5eb",
            "Auth-Token": getAuthKey()
        };

        try {
            const result = await axios.get(this.url + "/admin/" + locationId + "/client/" + clientId, {
                headers: headers
            });

            return result.data;

        }
        catch (error) {
            console.log('getClient', error);
        }
    }
 
}

export default Service;